/*
 * Copyright 2018- The Pixie Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';
import MDX from '@mdx-js/runtime';

// This is a circular dependency, but only at declaration time - the usages fire after definition.
// eslint-disable-next-line import/no-cycle
import mdxComponents from './index';

const parseMd = (input) => (
  <MDX components={mdxComponents}>
    {input}
  </MDX>
);
export default parseMd;
